* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}
.container {
  display: flex;
  background-color: #efefef;
}
main {
  width: 100%;
  padding: 20px;
}
.sidebar {
  background: #ffffff;
  color: #fff;
  height: 100vh;
  width: 200px;
  transition: all 0.5s;
  border-right: 3px;
}
.top_section {
  display: flex;
  align-items: center;
  padding: 15px 10px;
}
.logo {
  font-size: 30px;
}
.bars {
  display: flex;
  font-size: 25px;
  margin-left: 5px;
  margin-top: 5px;

  color: gray;
}
.link {
  display: flex;
  color: #a8a8a8d4;
  padding: 10px 15px;
  gap: 15px;
  transition: all 0.5s;
}
.link:hover {
  background: rgb(255, 255, 255);
  color: #04bef6d4;
  transition: all 0.5s;
}
.active {
  background: #fff;
  color: #04bef6d4;
}
.icon,
.link_text {
  font-size: 20px;
}
